<template lang="pug">
  v-container
    .info-munitipality(v-html="content")
</template>

<script>
import MainService from '@/services/main';

export default {
  name: 'InfoMunitipality',
  data() {
    return {
      content: '',
    };
  },
  created() {
    this.$rHandler(
      MainService.getMunitipalityInfo(this.$route.params.id),
      (response) => {
        this.content = response.data.data.text;
      },
      (response) => {
        this.content = response.data.result_message;
      },
    );
  },
};
</script>
